import { format } from 'date-fns';
import pakaiClass from 'pakai-class';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Times } from '../../assets/icons';
import userService from '../../axios/services/userService';
import Button from '../../components/Button';
import TextField from '../../components/Forms/TextField';
import StatusHistory from '../../components/StatusHistory';
import FileCard from '../../components/templates/FileCard';
import RejectDialog from '../../components/templates/RejectDialog';
import SimpleCard from '../../components/templates/SimpleCard';
import { StatusCard, StatusCardWrapper } from '../../components/templates/StatusCard';
import VerificationAction from '../../components/templates/VerificationAction';
import { userActions } from '../../redux/actions/userActions';
import { UserRoleTypes } from '../../utils/enums/userRoleTypes';
import ApprovalCard from './ApprovalCard';
import styles from './user.module.scss';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const DetailTab = ({ index }) => {
  const dispatch = useDispatch();
  const { key, item } = useSelector(state => state.user.tabs[index]);
  const [showDialog, setShowDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getDetail = useCallback(
    () => dispatch(userActions.getDetail(key, index)),
    [dispatch, key, index]
  )

  const approveUser = () => {
    setIsLoading(true)
    userService.approve(key)
      .then(() => getDetail())
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  const rejectUser = (values) => {
    setIsLoading(true)
    userService.reject(key, values)
      .then(() => {
        setShowDialog(false)
        getDetail()
      })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getDetail()
  }, [getDetail])

  return !item ? null
    :
    <div className="row">
      <div className="col-md-6">
        <SimpleCard className="mb-24">
          <p className="font-500 mb-12">Informasi Pengguna</p>
          <hr />
          <div>
            <TextField
              className="mb-24"
              readOnly
              label="Peran"
              value={UserRoleTypes.getStr(item.role)}
            />
            <TextField
              className="mb-24"
              readOnly
              label="Nama Lengkap"
              value={item.fullName}
            />
            <TextField
              className="mb-24"
              readOnly
              label="Email"
              value={item.email}
            />
            <TextField
              readOnly
              label="Nomor Handphone"
              value={item.phoneNumber}
            />
            {
              item.status.updatedAt &&
              <>
                <TextField
                  className="mb-24 mt-24"
                  readOnly
                  label="NIK"
                  value={item.identificationNumber}
                />
                <TextField
                  className="mb-24"
                  readOnly
                  label="Tempat Lahir"
                  value={item.placeOfBirth}
                />
                <TextField
                  className="mb-24"
                  readOnly
                  label="Tanggal Lahir"
                  value={format(new Date(item.dateOfBirth), 'd MMMM yyyy')}
                />
                <TextField
                  className="mb-24"
                  readOnly
                  label="Alamat Tempat Tinggal Terkini"
                  value={item.address}
                />
                <div className="mt-32">
                  <p className="font-500 mb-12">Berkas Pengguna</p>
                  <hr />
                  <FileCard
                    className="mb-12"
                    title="Foto KTP"
                    filename={item.identificationArchive.idCard.file.name}
                    url={item.identificationArchive.idCard.file.url}
                  />
                  <FileCard
                    title="Foto Diri Dengan KTP"
                    filename={item.identificationArchive.selfPhoto.file.name}
                    url={item.identificationArchive.selfPhoto.file.url}
                  />
                </div>
              </>
            }
          </div>
        </SimpleCard>
      </div>
      <div className="col-md-6">
        <SimpleCard loading={isLoading} className="mb-24">
          <p className="font-500 mb-12">Status</p>
          <hr />
          <StatusCardWrapper>
            {/* <StatusCard
              title="Password Set"
              value={item.status.isPasswordSet}
            /> */}
            {/* <StatusCard
              title="Form Filled"
              value={item.status.updatedAt}
            /> */}
            <StatusCard
              title="Aktif"
              value={item.status.isPasswordSet}
            />
            {/* <StatusCard
              title="Verified"
              value={item.status.verifiedAt}
            /> */}
          </StatusCardWrapper>
          {
            (item?.updatedAt && !item?.status?.verifiedAt && item?.role !== UserRoleTypes.SUPERVISOR)
            &&
            <div className="mt-32">
              <p className="font-500 mb-12">Verifikasi User</p>
              <hr />
              {
                !item.status.verifiedAt && item.status.rejectReason ?
                  <div>
                    <div className="d-flex justify-content-between flex-wrap mb-16 font-size-14">
                      <p className="mr-16 text-dark-gray">Alasan Penolakan :</p>
                      <p>{item.status.rejectReason}</p>
                    </div>
                    <Button
                      disabled
                      className={pakaiClass(styles.verifyButton, styles.rejected)}
                    >
                      <Times />
                      <span>Pengajuan ditolak</span>
                    </Button>
                  </div>
                  :
                  <VerificationAction
                    onApprove={approveUser}
                    onReject={() => setShowDialog(true)}
                  />
              }
              <RejectDialog
                show={showDialog}
                onClose={() => setShowDialog(false)}
                onCancel={() => setShowDialog(false)}
                onSubmit={rejectUser}
                modalTitle="Tolak Pengajuan User"
                loading={isLoading}
              />
            </div>
          }
        </SimpleCard>
        <div className="row mb-24">
          <div className="col-md-6">
            <ApprovalCard
              title="Buka blokir"
              requestType="USER_UNLOCK"
              requestId={key}
              variant="outline-danger"
              disabled={!Boolean(item.status.locked >= 3)}
              message={Boolean(item.status.locked >= 3) ? "Akun Ini Terkunci, Klik untuk membuka kunci" : "Akun ini Telah aktif"}
            />
          </div>
          <div className="col-md-6">
            <ApprovalCard
              title={item.status.suspend ? "Un-Blokir" : "Blokir"}
              requestType="USER_UNSUSPEND"
              requestId={key}
              variant="danger"
              message={item.status.suspend ? "Akun ini ditangguhkan, Batalkan penangguhan" : "Klik untuk menangguhkan"}
            />
          </div>
        </div>
        <StatusHistory
          statusHistories={item.statusHistories}
        />
      </div>
    </div>
}

export default DetailTab
