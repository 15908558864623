import React from 'react'
import {
  faListCheck,
  faShield,
  faLock,
  faUser,
  faMasksTheater
} from "@fortawesome/free-solid-svg-icons";
import RoundBadge from '../../components/Badge/RoundBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

const UserStepStatus = (user) => {
  const { forExcel, status: users } = user
  console.log(user)
  const { status } = users
  const getFormFilled = () => (
    forExcel
      ? (status?.updatedAt ? <span>Form Diisi Pada: {format(new Date(status?.updatedAt), "yyyy-MM-dd HH:mm:ss")}, </span> : <span>Form Belum Diisi, </span>)
      :
      <RoundBadge
        title={status?.updatedAt ? `Form Diisi Pada: ${format(new Date(status?.updatedAt), "yyyy-MM-dd HH:mm:ss")}` : 'Form Belum Diisi'}
        active={status?.updatedAt}
        variant="warning"
        icon={<FontAwesomeIcon icon={faListCheck} size="xs" />}
      />
  );

  const getVerified = () => (
    forExcel
      ? (status?.verifiedAt && status?.rejectReason ? <span>Ditolak: {status?.rejectReason}, </span> : status?.verifiedAt ? <span>Diverifikasi pada: {format(new Date(status?.verifiedAt), 'yyyy-MM-dd HH:mm:ss')}, </span> : <span>Belum Diverifikasi, </span>)
      :
      <RoundBadge
        title={status?.verifiedAt && status?.rejectReason ? `Ditolak: ${status?.rejectReason}` : status?.verifiedAt ? `Diverifikasi pada: ${format(new Date(status?.verifiedAt), 'yyyy-MM-dd HH:mm:ss')}` : 'Belum Diverifikasi'}
        active={status?.verifiedAt || status?.rejectReason}
        variant={status?.verifiedAt && status?.rejectReason ? "danger" : "primary"}
        icon={<FontAwesomeIcon icon={faShield} size="sm" />}
      />
  );

  const getLocked = () => (
    forExcel
      ? (status?.locked >= 3 ? <span>Dikunci, </span> : <span>Tidak Dikunci, </span>)
      :
      <RoundBadge
        title={status?.locked >= 3 ? `Dikunci` : 'Tidak Dikunci'}
        active={status?.locked >= 3}
        variant="danger"
        icon={<FontAwesomeIcon icon={faLock} size="xs" />}
      />
  );

  const getActive = () => (
    forExcel
      ? (status?.active ? <span>User Aktif, </span> : <span>User Tidak Aktif, </span>)
      :
      <RoundBadge
        title={status?.active ? `User Aktif` : 'User Tidak Aktif'}
        active={status.active}
        variant="success"
        icon={<FontAwesomeIcon icon={faUser} size="xs" />}
      />
  );

  const getPresume = () => (
    forExcel
      ? (status?.presume ? <span>{status.presumeDescription}, </span> : <span>Tidak Diduga, </span>)
      :
      <RoundBadge
        title={status?.presume ? status.presumeDescription : 'Tidak Diduga'}
        active={status?.verifiedAt || user?.status?.identificationArchive?.idCard?.verifiedAt || status?.rejectReason || user?.status?.company[0]?.status.rejectReason ? true : status.presume}
        variant={status?.verifiedAt || user?.status?.identificationArchive?.idCard?.verifiedAt ? user?.status?.company[0]?.status.rejectReason ? "danger": "secondary" : 'danger'}
        icon={<FontAwesomeIcon icon={faMasksTheater} size="xs" />}
      />
  );

  return (
    <div className="d-flex align-items-center justify-content-center">
      {getLocked()}
      {getPresume()}
    </div>
  )
}

export default UserStepStatus